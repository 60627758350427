export default {
  namespaced: true,
  modules: {
    
  },
  state: {
    initialState: {
      companyName: '',
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      mail: '',
      confirmMail: '',
      postalCode: '',
      pref: '',
      city: '',
      phoneNumber: '',
      contactContent: '',
      contactMsg: '',
    },
    initialPostalCode: {
      zip1: '',
      zip2: '' 
    },
    request: {
      companyName: '',
      firstName: '',
      lastName: '',
      firstNameKana: '',
      lastNameKana: '',
      mail: '',
      confirmMail: '',
      postalCode: '',
      pref: '',
      city: '',
      phoneNumber: '',
      contactContent: '',
      contactMsg: '',
    },
    postalCode: {
      zip1: '',
      zip2: '' 
    }
  },
  getters: {
    
  },
  mutations: {
    setCompanyName(state, value) {
      state.request.companyName = value
    },
    setFirstName(state, value) {
      state.request.firstName = value
    },
    setLastName(state, value) {
      state.request.lastName = value
    },
    setFirstNameKana(state, value) {
      state.request.firstNameKana = value
    },
    setLastNameKana(state, value) {
      state.request.lastNameKana = value
    },
    setMail(state, value) {
      state.request.mail = value
    },
    setConfirmMail(state, value) {
      state.request.confirmMail = value
    },
    setPostalCode(state) {
      state.request.postalCode = state.postalCode.zip1 + state.postalCode.zip2
    },
    setPref(state, value) {
      state.request.pref = value
    },
    setCity(state, value) {
      state.request.city = value
    },
    setPhoneNumber(state, value) {
      state.request.phoneNumber = value
    },
    setContactContent(state, value) {
      state.request.contactContent = value
    },
    setContactMsg(state, value) {
      state.request.contactMsg = value
    },
    setZip1(state, value) {
      state.postalCode.zip1 = value
    },
    setZip2(state, value) {
      state.postalCode.zip2 = value
    },
    resetVuexState(state) {
      Object.assign(state.request, JSON.parse(localStorage.getItem('initialState')));
    },
    resetVuexPostalCode(state) {
      Object.assign(state.postalCode, JSON.parse(localStorage.getItem('initialPostalCode')));
    }
  },
  actions: {
    updateCompanyName({ commit, state }, e) {
      commit('setCompanyName', e.target.value)
    },
    updateFirstName({ commit, state }, e) {
      commit('setFirstName', e.target.value)
    },
    updateLastName({ commit, state }, e) {
      commit('setLastName', e.target.value)
    },
    updateFirstNameKana({ commit, state }, e) {
      commit('setFirstNameKana', e.target.value)
    },
    updateLastNameKana({ commit, state }, e) {
      commit('setLastNameKana', e.target.value)
    },
    updateMail({ commit, state }, e) {
      commit('setMail', e.target.value)
    },
    updateConfirmMail({ commit, state }, e) {
      commit('setConfirmMail', e.target.value)
    },
    updatePref({ commit, state }, e) {
      commit('setPref', e.target.value)
    },
    updateCity({ commit, state }, e) {
      commit('setCity', e.target.value)
    },
    updatePhoneNumber({ commit, state }, e) {
      commit('setPhoneNumber', e.target.value)
    },
    updateContactContent({ commit, state }, e) {
      commit('setContactContent', e.target.value)
    },
    updateContactMsg({ commit, state }, e) {
      commit('setContactMsg', e.target.value)
    },
    updateZip1({ commit, state }, e) {
      commit('setZip1', e.target.value)
      commit('setPostalCode')
    },
    updateZip2({ commit, state }, e) {
      commit('setZip2', e.target.value)
      commit('setPostalCode')
    }
  }
}