<style lang="scss">
  #home {
    #home-section {
      height: 745px;
      background-image: url(../assets/BG.svg);
      background-position: left 0% top 105%;
      @media screen and (min-width: 1441px) {
        background-size: cover;
      }
      @media screen and (max-width: 767px) {
        background-position: left 50% top 105%;
      }

      img {
        max-width: 100%;
      }

      .page-header {
        display: flex;
        justify-content: space-between;
        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }

      .wrapper {
        max-width: 944px;
        margin: 0 auto;
        padding-top: 48px;
        @media (max-width: 767px) {
          max-width: 342px;
          margin: 0 auto;
          padding-top: 32px;
        }

        .btn-login {
          // height: 16px;
          // width: 63px;
          margin-right: 8px; // margin-right: 32px;
          font: normal normal normal 16px/39px Hiragino Sans;
          letter-spacing: 0px;
          color: #333333;
          @media (max-width: 767px) {
            display: none;
          }
        }

        .routerLink {
          text-decoration: none;
        }

        .btn-contact {
          font: normal normal normal 16px/39px Hiragino Sans;
          letter-spacing: 0px;
          color: #ffffff;
          background-color: #000000;
          padding: 24px 48px;
          @media (max-width: 767px) {
            margin-top: 43px;
          }
        }
      }

      .home-content {
        margin-top: 75px;
        text-align: center;
        @media (max-width: 767px) {
          margin-top: 31px;
        }

        .main-copy {
          width: 100%;
          @media (max-width: 767px) {
            display: none;
          }
        }

        .main-title {
          text-align: left;
          font-size: 18px;
          font-family: Hiragino Sans;
          letter-spacing: 0px;
          color: #333333;
          padding: 0 24px;
          @media (min-width: 768px) {
            display: none;
          }
        }

        .desktop {
          max-width: 680px;
          max-height: 363px;
          margin: 30px auto 0 auto;
          position: relative;

          .patent {
            color: #ffffff;
            background-color: #000000;
            font-size: 14px;
            font-family: 'DNP', '秀英角ゴシック金,' 'Std';
            font-weight: bold;
            padding: 0 12px;
            position: absolute;
            left: 88%;
            top: 80%;
            @media (max-width: 767px) {
              position: absolute;
              left: 40%;
              top: 105%;
            }
          }
        }
      }
    }
    .section {
      $grey-lighter: #f6f6f6;
      &:nth-child(odd) {
        background-color: $grey-lighter;
      }
    }
  }
</style>

<template>
  <v-app id="home">
    <!-- ヘッダー・ホームコンテンツ -->
    <section id="home-section">
      <header class="page-header wrapper">
        <h1>
          <a href="/">
            <img src="../assets/LOGO_H.svg" />
          </a>
        </h1>

        <div class="btn">
          <!-- <v-btn
                depressed
                class="btn-login"
              >
                ログイン
              </v-btn> -->
          <v-btn
            depressed
            rounded
            text
            class="btn-login"
            href="https://v3.repchecker.jp"
          >
            ログイン
          </v-btn>

          <!-- <v-btn
                rounded
                class="btn-contact"
                to="/contact"
              >
                お問合わせ
              </v-btn> -->
          <v-btn
            rounded
            class="btn-contact"
            href="https://www.contact.psinc.jp"
          >
            お問合わせ
          </v-btn>
        </div>
      </header>

      <v-layout column align-center justify-center>
        <div class="home-content">
          <h2 class="main-copy">
            <img src="../assets/メインコピー.svg" />
          </h2>
          <h2 class="main-title">
            ホテル・旅館向け<br />
            クチコミ&レベニューマネジメント<br />
            一体型マーケティング・クラウドシステム
          </h2>
          <div class="desktop">
            <img src="../assets/Desktop.png" />
            <p class="patent">特許取得</p>
          </div>
        </div>
      </v-layout>
    </section>

    <IntroductionResults />

    <Solution />

    <CaseStudy />

    <ContactBtn />

    <PsFooter />
  </v-app>
</template>

<script>
  import IntroductionResults from '../components/IntroductionResults'
  import Solution from '../components/Solution'
  import CaseStudy from '../components/CaseStudy'
  import ContactBtn from '../components/ContactBtn'
  import PsFooter from '../components/PsFooter'

  export default {
    name: 'Home',
    components: {
      IntroductionResults,
      Solution,
      CaseStudy,
      ContactBtn,
      PsFooter,
    },
  }
</script>
