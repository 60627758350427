<style lang="scss" scoped>
@import '../assets/scss/_introductionResults.scss';
</style>

<template>
  <!-- INTRODUCTION RESULTS -->
  <section class="intro-results">
    <v-layout
      column
      wrap
      class="my-5 wrapper"
      align-center
    >
      <v-flex xs12 sm12 md12 lg12 class="my-3" style="text-align: center;">
        <div class="text-xs-center">
          <div class="tit-intro">INTRODUCTION&nbsp;RESULTS</div>
          <h2 class="headline-1">
            <img src="../assets/テキスト.svg" alt="導入実績(抜粋)">
          </h2>
          <p class="subheading-1">
            <span class="text-1400">1,400</span>&nbsp;施設以上の有名ホテルチェーン・旅館などに導入済み！
          </p>
          <p class="subheading-2">
            <span class="text-1400">1,400</span>&nbsp;施設以上の<br>有名ホテルチェーン・旅館など<br>に導入済み！
          </p>
        </div>
      </v-flex>

      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <v-flex xs12 md12>
              <v-card
                class="card-hotel"
                flat
              >
                <v-card-title class="card-title">
                  <div class="headline text-xs-center">温泉旅館・ホテル</div>
                </v-card-title>
                <!-- PC・モニター用 -->
                <v-card-text class="hotel-lists-a">
                  西村屋本館様 / 西村屋ホテル招月庭様 / 旅館こうろ様 / 不死王閣様 / 淡路島観光ホテル様 / ほどあいの宿六峰舘様 / 伝承千年の宿佐勘様 /<br>
                  北こぶし知床 ホテル&リゾート様 / 金乃竹塔ノ澤様 /  鬼怒川温泉あさや様 / ホテル華の湯 / トーホウリゾート様 / ホテルサンシャイン鬼怒川様 /<br>
                  秋保温泉きよ水様 / 銀山壮様 / カラカミ観光様 / ハマノホテルズ様 / サンザグループ様 / 城西館様 / 道後舘様 / 道後八千代様 / びわこ緑水亭様 /<br>
                  小樽旅亭藏群様 / THE HIRAMATSU HOTELS & RESORTS様 / 鶴雅グループ様 / 大江戸温泉物語様 / 聚楽グループ / 他
                </v-card-text>
                <!-- スマホ・タブレット用 -->
                <v-card-text class="hotel-lists-b">
                  西村屋本館様 / 西村屋ホテル招月庭様 /<br>
                  旅館こうろ様 / 不死王閣様 / 淡路島観光ホテル様 /<br>
                  ほどあいの宿六峰舘様 / 伝承千年の宿佐勘様 /<br>
                  北こぶし 知床 ホテル＆リゾート様 /<br>
                  金乃竹塔ノ澤様 / 鬼怒川温泉あさや様 /<br>
                  ホテル華の湯 / トーホウリゾート様 /<br>
                  ホテルサンシャイン鬼怒川様 /秋保温泉きよ水様 /<br>
                  銀山荘様 / カラカミ観光様 / ハマノホテルズ様 /<br>
                  サンザグループ様 / 城西館様 / 道後舘様 /<br>
                  道後八千代様 / びわこ緑水亭様 / 小樽旅亭藏群様 /<br>
                  THE HIRAMATSU HOTELS & RESORTS様 /<br>
                  鶴雅グループ様 / 大江戸温泉物語様 /<br>
                  聚楽グループ様 / 他
                </v-card-text>
              </v-card>
            </v-flex>

            <v-flex xs12 md12>
              <v-card
                class="card-hotel"
                flat
              >
                <v-card-title class="card-title city-hotel-a">
                  <div class="headline text-xs-center">宿泊特化型ホテルチェーン、シティホテルチェーン</div>
                </v-card-title>
                <v-card-title class="city-hotel-b">
                  <div class="headline text-xs-center">宿泊特化型ホテルチェーン、<br>シティホテルチェーン</div>
                </v-card-title>
                <!-- PC・モニター用 -->
                <v-card-text class="hotel-lists-a">
                  マイステイズ・ホテル・マネジメント様 / ダイワロイネットホテルズ様 / 藤田観光様 / 森トラスト・ホテルズ＆リゾーツ様 /<br>
                  ロイヤルパークホテルズ様 / 三交インホテルズ様 / スーパーホテル様 / ユニゾホテル様 / 東急リゾーツ＆ステイ様 / アークホテルグループ様 /<br>
                  京王プレリアホテル様 / サンルートホテルチェーン様 / JR九州ホテルズ様 / 名鉄ホテルグループ様 / セレクトホテルズグループ様 /<br>
                  ウイングインターナショナル様 / WBFホテル＆リゾーツ様 / ビスタホテルマネジメント様 / JR四国ホテルグループ様 / 変なホテルグループ様 /<br>
                  ファーストキャビン様 / 東急EXイン様 / プレミアホテルグループ様 / ユニマットプレシャス様 / ネストホテルジャパン様 / 更紗ホテルズ様 /<br>
                  センチュリオンホテルズ様 / JHAT様 / ABアコモ様 / 価値開発様 / 東武ホテルマネジメント様 / 盛岡シティホテルグループ様 / 東洋観光グループ様 /
                  グリーンホスピタリティマネジメント様 / プリンスホテル様 / 他
                </v-card-text>
                <!-- スマホ・タブレット用 -->
                <v-card-text class="hotel-lists-b">
                  マイステイズ・ホテル・マネジメント様 /<br>
                  ダイワロイネットホテルズ様 / 藤田観光様 /<br>
                  森トラスト・ホテルズ＆リゾーツ様 /<br>
                  ロイヤルパークホテルズ様 / 三交インホテルズ様 /<br>
                  スーパーホテル様 / ユニゾホテル様 /<br>
                  東急リゾーツ&ステイ様 / アークホテルグループ様 /<br>
                  京王プレリアホテル様 /<br>
                  サンルートホテルチェーン様 / JR九州ホテルズ様 /<br>
                  名鉄ホテルグループ様 / セレクトホテルズグループ様 /<br>
                  ウイングインターナショナル様 /<br>
                  WBFホテル＆リゾーツ様 /<br>
                  ビスタホテルマネジメント様 /<br>
                  JR四国ホテルグループ様 / 変なホテルグループ様 /<br>
                  ファーストキャビン様 / 京急EXイン様 /<br>
                  プレミアホテルグループ様 /<br>
                  ユニマットプレシャス様 / ネストホテルジャパン様 /<br>
                  更紗ホテルズ様 / センチュリオンホテルズ様 /<br>
                  JHAT様 / ABアコモ様 / 価値開発様 /<br>
                  東武ホテルマネジメント様 /<br>
                  盛岡シティホテルグループ様 / 東洋観光グループ様 /<br>
                  グリーンホスピタリティマネジメント様 /<br>
                  プリンスホテル様 / 他
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>

          <div>
            <p class="no-particular">順不同</p>
          </div>
          
        </v-container>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {

}
</script>