<style lang="scss" scoped>
@import '../assets/scss/_contactForm.scss';
</style>

<template>
  <div class="form-box">
    <form @submit.prevent="submit" autocomplete="on">
      <!-- ホテル名 -->
      <div class="hotel-name box-title">
        <p>
          貴社名（ホテル名・施設名）
        </p>
        <input 
          type="text"
          name="companyName"
          placeholder="（必須）"
          @input="updateCompanyName"
          :value="request.companyName"
          data-vv-as="貴社名（ホテル名・施設名）"
          v-validate="'required'"
        >
        <span class="error-msg" v-show="errors.has('companyName')">{{ errors.first('companyName') }}</span>
      </div>

      <!-- 担当者名：漢字 -->
      <div class="name-box boxs">
        <div class="first-name box-title">
          <p>
            ご担当者様（漢字）- 性
          </p>
          <input
            type="text"
            name="firstName"
            placeholder="  性(必須)"
            @input="updateFirstName"
            :value="request.firstName"
            data-vv-as="ご担当者様（漢字）- 性"
            v-validate="'required|regex:^([一-龥]*)$'"
          >
          <span class="error-msg" v-show="errors.has('firstName')">{{ errors.first('firstName') }}</span>
        </div>
        <div class="last-name box-title">
          <p>
            ご担当者様（漢字）- 名
          </p>
          <input 
            type="text"
            name="lastName"
            placeholder="  名(必須)"
            @input="updateLastName"
            :value="request.lastName"
            data-vv-as="ご担当者様（漢字）- 名"
            v-validate="'required|regex:^([一-龥]*)$'"
          >
          <span class="error-msg" v-show="errors.has('lastName')">{{ errors.first('lastName') }}</span>
        </div>
      </div>

      <div class="name-box boxs">
        <div class="first-name box-title">
          <p>
            ご担当者様（カナ）- セイ
          </p>
          <input 
            type="text"
            name="firstNameKana"
            placeholder="  セイ(必須)"
            @input="updateFirstNameKana"
            :value="request.firstNameKana"
            data-vv-as="ご担当者様（カナ）- セイ"
            v-validate="'required|regex:^([ァ-ヴ][ァ-ヴー・]*)$'"
          >
          <span class="error-msg" v-show="errors.has('firstNameKana')">{{ errors.first('firstNameKana') }}</span>
        </div>
        <div class="last-name box-title">
          <p>
            ご担当者様（カナ）- メイ
          </p>
          <input 
            type="text"
            name="lastNameKana"
            placeholder="  メイ(必須)"
            @input="updateLastNameKana"
            :value="request.lastNameKana"
            data-vv-as="ご担当者様（カナ）- メイ"
            v-validate="'required|regex:^([ァ-ヴ][ァ-ヴー・]*)$'"
          >
          <span class="error-msg" v-show="errors.has('lastNameKana')">{{ errors.first('lastNameKana') }}</span>
        </div>
      </div>

      <!-- メールアドレス -->
      <div class="mail box-title boxs">
        <p>
          連絡先メールアドレス
        </p>
        <input 
          type="text"
          name="mail"
          placeholder="（必須）"
          @input="updateMail"
          :value="request.mail"
          data-vv-as="連絡先メールアドレス"
          v-validate="'required|email'"
        >
        <span class="error-msg" v-show="errors.has('mail')">{{ errors.first('mail') }}</span>
      </div>

      <!-- 電話番号 -->
      <div class="phone-number box-title boxs">
        <p>
          連絡先電話番号
        </p>
        <div class="phone-number-form">
          <input
            type="tel"
            name="phoneNumber"
            placeholder="（必須）"
            @input="updatePhoneNumber"
            :value="request.phoneNumber"
            data-vv-as="連絡先電話番号"
            v-validate="'required|numeric'"
          >
          <span class="error-msg" v-if="errors.has('phoneNumber')">{{ errors.first('phoneNumber') }}</span>
        </div>
      </div>

      <!-- お問い合わせ内容 -->
      <div class="contact box-title boxs">
        <p>
          お問い合わせ内容
        </p>
        <div class="contact-form">
          <div class="select-wrap">
            <select
              id="select"
              name="contactContent"
              @input="updateContactContent"
              :value="request.contactContent"
              data-vv-as="お問い合わせ内容"
              v-validate="'required'"
            >
            <!-- val1,2,3の色を黒に変える -->
              <option 
                value=""
                style="display: none;"
                selected
              >
                (必須)
              </option>
              <option value="1">SELECT.01</option>
              <option value="2">SELECT.02</option>
              <option value="3">SELECT.03</option>
            </select>
          </div>
        </div>
        <span class="error-msg" v-if="errors.has('contactContent')">{{ errors.first('contactContent') }}</span>
      </div>

      <!-- お問い合わせ内容記載欄 -->
      <div class="Inquiry-column box-title boxs">
        <p>
          内容記載欄<span class="contact-ex">お問合せ内容を下記記入欄へなるべく具体的にご入力ください。</span>
        </p>
        <textarea
          id=""
          name="contactMsg"
          placeholder="（必須）"
          @input="updateContactMsg"
          :value="request.contactMsg"
          data-vv-as="お問い合わせ内容"
          v-validate="'required'"
        />
        <span class="error-msg" v-if="errors.has('contactMsg')">{{ errors.first('contactMsg') }}</span>
      </div>
      <div class="btn-group">
        <v-btn
          @click.native="reset"
          class="reset-button"
          depressed
        >
          リセット
        </v-btn>
        <v-btn
          type="submit"
          class="btn-confirm"
          rounded
        >
          お問合わせ内容のご確認
        </v-btn>
      </div>
    </form>
  </div>
</template>

<script>
import axios from 'axios'
import jsonpAdapter from 'axios-jsonp'
import { mapActions } from 'vuex'
import $ from 'jquery';
import prefectures from '../lib/prefectures'

export default {
  data () {
    return {
    }
  },
  created() {
    $(window).off('popstate')
  },
  computed: {
    prefData() {
      return prefectures
    },
    request() {
      return this.$store.state.contact.request
    },
    postalCode() {
      return this.$store.state.contact.postalCode
    },
  },
  methods: {
    ...mapActions('contact', [
      'updateCompanyName',
      'updateFirstName',
      'updateLastName',
      'updateFirstNameKana',
      'updateLastNameKana',
      'updateMail',
      'updateConfirmMail',
      'updatePostalCode',
      'updatePref',
      'updateCity',
      'updatePhoneNumber',
      'updateContactContent',
      'updateContactMsg',
      'updateZip1',
      'updateZip2'
    ]),
    submit() {
      this.$validator.validateAll().then((result) => {
        if(result) {
          if(this.request.mail) {
            this.$router.push('contact/confirm')
          } else {
            window.alert('メールアドレスが合致しません')
          }
        } else {
          window.alert('入力内容に誤りがあります')
        }
      })
    },
    addressAutoCompletion(zip1, zip2) {
      const postalCode = zip1 + zip2
      const url =`http://zipcloud.ibsnet.co.jp/api/search?zipcode=${postalCode}`
      axios({
        url: url,
        adapter: jsonpAdapter
      })
      .then((res) => {
        if(!res.data.results) return
        const address1 = res.data.results[0].address1
        const address2 = res.data.results[0].address2
        const address3 = res.data.results[0].address3
        this.request.pref = address1
        this.request.city = address2 + address3
      })
    },
    reset() {
      const reset = window.confirm('入力内容をリセットしてもよろしいでしょうか？')
      if(reset) {
        Object.keys(this.request).forEach((key) => {
          this.$set(this.request, key, '')
        })
        Object.keys(this.postalCode).forEach((key) => {
          this.$set(this.postalCode, key, '')
        })
      }
    }
  }
}
</script>