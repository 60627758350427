<style lang="scss" scoped>
@import '../assets/scss/_solution.scss';
</style>

<template>
  <!-- SOLUTION -->
  <section class="solution">
    <v-layout
      column
      wrap
      class="my-5 wrapper"
      align-center
    >
      <v-flex xs12 sm4 class="my-3" style="text-align: center;">
        <div class="text-xs-center" style="margin-bottom: 25px;">
          <div class="tit-sol">SOLUTION</div>
          <h2 class="sol-headline-a">
            RepCheckerが実現する宿泊業の生産性向上
          </h2>
          <h2 class="sol-headline-b">
            RepCheckerが実現する<br>宿泊業の生産性向上
          </h2>
        </div>
      </v-flex>

      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <!-- STEP1 -->
            <v-flex xs12 md3>
              <v-card
                flat
                class="elevation-0 transparent"
              >
                <div class="step-img">
                  <img src="../assets/number/no1.svg" alt="STEP1">
                </div>
                <div class="step-fig fig1">
                  <img src="../assets/fig/fig_001/fig_001.png" alt="">
                </div>
                <v-card-title class="sol-card-title title1">
                  <div class="headline text-xs-center">タイムリーな情報収集</div>
                </v-card-title>
                <v-card-text style="padding: 0;">
                  情報収集が短時間で行えるようになり、仕事全体を劇的に効率化する事が可能です。
                </v-card-text>
              </v-card>
            </v-flex>

            <div class="arrow">
              <img src="../assets/arrow-bottom.png" alt="">
            </div>

            <!-- STEP2 -->
            <v-flex xs12 md3>
              <v-card
                flat
                class="elevation-0 transparent"
              >
                <div class="step-img">
                  <img src="../assets/number/no2.svg" alt="STEP2">
                </div>
                <div class="step-fig fig2">
                  <img src="../assets/fig/fig_002/fig_002.png" alt="">
                </div>
                <v-card-title class="sol-card-title title2">
                  <div class="headline text-xs-center">分析 + 課題抽出</div>
                </v-card-title>
                <v-card-text style="padding: 0;">
                  自施設のレベルを近隣競合施設や業界全体と比較し、強み、弱みを分析して課題を洗い出します。
                </v-card-text>
              </v-card>
            </v-flex>

            <div class="arrow">
              <img src="../assets/arrow-bottom.png" alt="">
            </div>

            <!-- STEP3 -->
            <v-flex xs12 md3>
              <v-card
                flat
                class="elevation-0 transparent"
              >
                <div class="step-img">
                  <img src="../assets/number/no3.svg" alt="STEP3">
                </div>
                <div class="step-fig fig3">
                  <img src="../assets/fig/fig_003/fig_003.png" alt="">
                </div>
                <v-card-title class="sol-card-title title3">
                  <div class="headline text-xs-center">改善策検討 + 実施</div>
                </v-card-title>
                <v-card-text style="padding: 0;">
                  取得したデータをもとに問題点の把握を行い、その結果をもとに改善策を検討し、より良いサービスの提供を目指します。
                </v-card-text>
              </v-card>
            </v-flex>

            <div class="arrow">
              <img src="../assets/arrow-bottom.png" alt="">
            </div>

            <!-- STEP4 -->
            <v-flex xs12 md3>
              <v-card
                flat
                class="elevation-0 transparent"
              >
                <div class="step-img">
                  <img src="../assets/number/no4.svg" alt="STEP4">
                </div>
                <div class="step-fig fig4">
                  <img src="../assets/fig/fig_004/fig_004.png" alt="">
                </div>
                <v-card-title class="sol-card-title title4" style="padding: 8px 0 9px;">
                  <div class="headline text-xs-center">生産性向上<br>(業務効率UP + 収益改善)</div>
                </v-card-title>
                <v-card-text style="padding: 0;">
                  業務効率化により、人的（時間的）コスト削減や生産性の向上が期待できます。
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  
}
</script>