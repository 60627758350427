import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Contact from './modules/contact'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    contact: Contact
  },
  state: {
    test: ''
  },
  getters: {
    
  },
  mutations: {
    
  },
  actions: {
    
  },
  plugins: [createPersistedState()]
})
