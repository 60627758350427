<style lang="scss" scoped>
@import '../assets/scss/_caseStudy.scss';
</style>

<template>
  <!-- CASE STUDY -->
  <section class="case-study">
    <v-layout
      column
      wrap
      class="my-5 wrapper"
      align-center
    >
      <v-flex xs12 sm12 md12 lg12 class="my-3" style="text-align: center;">
        <div class="text-xs-center" style="margin-bottom: 25px;">
          <div class="tit-case">CASE&nbsp;STUDY</div>
          <h2 class="case-head-a">
            <img src="../assets/活用事例のご紹介.svg" alt="">
          </h2>
          <h2 class="case-head-b">
            活用事例のご紹介
          </h2>
        </div>
      </v-flex>

      <!-- CASE1 -->
      <v-flex xs12>
        <v-container grid-list-xl>
          <v-layout row wrap align-center>
            <v-flex xs12 md12>
              <v-card class="case-card">
                <v-card-title class="case-title" style="padding: 0; margin-bottom: 20px;">
                  <div class="case-no">
                    <img src="../assets/case.svg" alt="">
                    <img src="../assets/case-no/01.svg" alt="">
                  </div>
                  <div class="case-card-title1">
                    <h3 class="content-info">広告費削減</h3>
                    <p class="sub-info">15施設運営事業者A社様</p>
                  </div>
                </v-card-title>
                <p class="sub-title-a">プロモーション費用を約&nbsp;<span class="sub-color">500</span>&nbsp;万円削減！</p>
                <p class="sub-title-b">プロモーション費用を<br>約&nbsp;<span class="sub-color">500</span>&nbsp;万円<br>削減！</p>
                <v-card-text class="case-text">
                  バリューチェーン分析を利用してOTAの評価点数改善の取組を行った。OTAの掲載順位アップの結果、OTAのプロモーション費用を3ヶ月で約500万円削減に成功。また、サービスが向上しリピート率アップにつながった。
                </v-card-text>
              </v-card>
            </v-flex>

            <!-- CASE2 -->
            <v-flex xs12 md12>
              <v-card class="case-card">
                <v-card-title style="padding: 0; margin-bottom: 20px;">
                  <div class="case-no">
                    <img src="../assets/case.svg" alt="">
                    <img src="../assets/case-no/02.svg" alt="">
                  </div>
                  <div class="case-card-title2">
                    <h3 class="content-info">業務時間削減</h3>
                    <p class="sub-info">40施設運用事業者B社様</p>
                  </div>
                </v-card-title>
                <p class="sub-title-a">月間&nbsp;<ruby>5<rt>&#9679;</rt>5<rt>&#9679;</rt></ruby>&nbsp;時間の労働時間を削減！</p>
                <p class="sub-title-b">月間&nbsp;<ruby>5<rt>&#9679;</rt>5<rt>&#9679;</rt></ruby><br>&nbsp;時間の労働時間を削減！</p>
                <v-card-text class="case-text">
                  各OTAのクチコミ収集、返信、分析、報告、共有などの一連の業務に1日当たり3時間を費やしていたが、レップチェッカー導入、クチコミ管理機能を活用することで、大幅な業務改善となった。個々の施設内での情報共有に留まらず、グループ本部やグループの他施設での連携が可能となった。
                </v-card-text>
              </v-card>
            </v-flex>

            <!-- CASE3 -->
            <v-flex xs12 md12>
              <v-card class="case-card">
                <v-card-title style="padding: 0; margin-bottom: 20px;">
                  <div class="case-no">
                    <img src="../assets/case.svg" alt="">
                    <img src="../assets/case-no/03.svg" alt="">
                  </div>
                  <div class="case-card-title3">
                    <h3 class="content-info">サービス評価改善</h3>
                    <p class="sub-info">30施設運用事業者C社様</p>
                  </div>
                </v-card-title>
                <p class="sub-title-a">グループ施設平均評価点数を&nbsp;<span class="sub-color">3.1→3.7</span>&nbsp;へ改善！</p>
                <p class="sub-title-b">グループ施設平均評価点数を&nbsp;<span class="sub-color">3.1→3.7</span>&nbsp;<br>へ改善！</p>
                <v-card-text class="case-text">
                  CS向上が販売価格アップにつなげられるという仮説を立て、レップチェッカーを導入。現状の各OTAの評価点数の把握と改善点を抽出し取組を実施した結果、課題であった接客/サービスの評価点数アップに成功。また、「ホテルプライス」、「エリアプライス」を利用して競合施設の販売単価と評価点数を分析して、戦略的な料金設定により販売価格アップを実現。
                </v-card-text>
              </v-card>
            </v-flex>
          </v-layout>
        </v-container>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
export default {
  
}
</script>