<style lang="scss" scoped>
@import '../assets/scss/_contactComplete.scss';
</style>

<template>
  <div>
    <div class="contact-bg">
      <header class="contact-header contact-wrapper">
        <h1>
          <img src="../assets/LOGO_H.svg" alt="">
        </h1>
        <router-link to="/" class="routerLink">
          <v-btn
            rounded
            class="top-btn"
          >
            TOPへ戻る
          </v-btn>
        </router-link>
      </header>
    </div>

    <div class="contact-wrap">
      <div class="contact-orange">CONTACT</div>
      <div class="contact-h2">お問合せ</div>
    </div>

    <v-card>
      <v-card-title 
        class="complete-title"
      >
        お問い合わせいただき、ありがとうございました<br>
        お問合わせを受付いたしました。
      </v-card-title>
      
      <v-card-text
        class="complete-text"
      >
        担当スタッフが内容を確認し、折り返しご担当者様にご連絡いたします。<br>
        基本的には24時間以内（土日祝日を除く）にご返答させていただいておりますが、お問い合わせの内容によっては、回答に2、3日お時間をいただく場合もございます。<br>
        お待たせして大変恐縮ではございますが、ご回答まで今しばらくおまちくださいますようお願い申し上げます。<br>
        また、お問い合わせフォームよりご入力いただきましたアドレス宛にご確認のメールを送信させていただきました。<br>
        お手数ではございますが、ご確認いただけますようお願い致します。 <br>
        その他、何かご不明な点等ございましたら、お気軽にお問い合わせ下さいませ。
      </v-card-text>
    </v-card>

    <v-footer
    dark
    padless
    style="margin-top: 260px;"
  >
    <p class="copyright-footer">Copyright © Paradigmshift Inc. All Rights Reserved.</p>
  </v-footer>
  </div>
</template>

<script>
import $ from 'jquery';

export default {
  created()
  {
    $(window).off('scroll')
    $(function() {
      history.pushState(null, null, null)
      $(window).on('popstate', function() {
        history.pushState(null, null, null)
        window.alert('ブラウザバックは禁止なのでTOPボタンからお戻りください')
      })
    })
  }
}
</script>