import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import jQuery from 'jquery'
import VeeValidate, { Validator } from 'vee-validate'
import ja from 'vee-validate/dist/locale/ja'


global.jquery = jQuery
global.$ = jQuery
window.$ = window.jQuery = require('jquery')

Validator.localize('ja', ja);
Vue.use(VeeValidate, { 
  locale: ja,
  validity: true
});


Vue.config.productionTip = false


new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
