<style lang="scss" scoped>
  .contact-section {
    padding: 40px 0;
    @media (max-width: 767px) {
      max-width: 342px;
      margin: 0 auto;
    }

    .contact-description {
      font-size: 14px;
      font-family: Hiragino Sans;
      letter-spacing: 0px;
      color: #333333;
      @media (max-width: 767px) {
        text-align: left;
      }
    }

    .v-btn:not(.v-btn--round).v-size--default {
      height: 36px;
      min-width: 64px;
      padding: 24px 48px;
    }

    .routerLink {
      text-decoration: none;
    }

    .contact-btn {
      margin-top: 16px;
      color: #ffffff;
      background-color: #333333 !important;
      font: normal normal normal 16px/39px Hiragino Sans;
      letter-spacing: 0px;
    }
  }
</style>

<template>
  <section class="contact-section">
    <v-layout column wrap class="my-5 wrapper" align-center>
      <v-flex xs12 sm4 class="my-3" style="text-align: center">
        <p class="contact-description">
          各種お問い合せは、お問い合せフォームにて承っております。
        </p>

        <!-- <v-btn
          rounded
          class="contact-btn"
          to="/contact"
        >
          お問合わせ
        </v-btn> -->
        <v-btn rounded class="contact-btn" href="https://www.contact.psinc.jp">
          お問合わせ
        </v-btn>
      </v-flex>
    </v-layout>
  </section>
</template>

<script>
  export default {}
</script>
