import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Contact from '../components/Contact'
import ContactConfirm from '../views/ContactConfirm'
import ContactComplete from '../views/ContactComplete'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/contact/confirm',
    name: 'ContactConfirm',
    component: ContactConfirm
  },
  {
    path: '/contact/confirm/complete',
    name: 'ContactComplete',
    component: ContactComplete
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
