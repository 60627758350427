<style lang="scss" scoped>
@import '../assets/scss/_contact.scss';
</style>

<template>
  <section class="contact">
    <div class="contact-bg">
      <header class="contact-header contact-wrapper">
        <h1>
          <img src="../assets/LOGO_H.svg" alt="">
        </h1>
        <router-link to="/" class="routerLink">
          <v-btn
            rounded
            class="top-btn"
          >
            TOPへ戻る
          </v-btn>
        </router-link>
      </header>
    </div>

    <div class="explain-wrapper">
      <div class="contact-orange">CONTACT</div>
      <div class="contact-h2">お問合せ</div>

      <div class="contact-explain">
        <p>
          下記のフォームより必要事項を入力し、「入力内容確認」ボタンを押してください。<br>
          お問合わせの内容により担当者よりご返信させていただきます。
        </p>
      </div>
    </div>

    <!-- ここにお問い合わせフォームが入ります -->
    <ContactForm/>
    

    <v-footer
      dark
      padless
      style="margin-top: 10px;"
    >
      <p class="copyright-footer">Copyright © Paradigmshift Inc. All Rights Reserved.</p>
    </v-footer>
  </section>
</template>

<script>
import ContactForm from '../views/ContactForm'
export default {
  name: 'Contact',
  components: {
    ContactForm
  }
}
</script>