<style lang="scss" scoped>
@import '../assets/scss/_contactConfirm.scss';
</style>

<template>
<div>
  <div class="contact-bg">
    <header class="contact-header contact-wrapper">
      <h1>
        <img src="../assets/LOGO_H.svg" alt="">
      </h1>
      <v-btn
        rounded
        class="top-btn"
        to="/"
      >
        TOPへ戻る
      </v-btn>
    </header>
  </div>

  <div class="contact-wrap">
    <div class="contact-orange">CONTACT</div>
    <div class="contact-h2">お問合せ</div>
  </div>

  <div class="form-box">
    <form action="" @submit.prevent="submit">
      <dl>
        <dt class="hotel-form boxs form-title bg-white">貴社名</dt>
        <dd class="form-value values bg-white">{{ request.companyName }}</dd>

        <dt class="name-form boxs form-title bg-gray">担当者名</dt>
        <dd class="form-value values bg-gray">{{ request.firstName }}&nbsp;{{ request.lastName }}</dd>

        <dt class="name-kana-form boxs form-title bg-white">担当者名（カナ）</dt>
        <dd class="form-value values bg-white">{{ request.firstNameKana }}&nbsp;{{ request.lastNameKana }}</dd>

        <dt class="mail-form boxs form-title bg-gray">メールアドレス</dt>
        <dd class="form-value values bg-gray">{{ request.mail }}</dd>

        <dt class="phone-number-form boxs form-title bg-white">電話番号</dt>
        <dd class="form-value values bg-white">{{ request.phoneNumber }}</dd>

        <dt class="contact-form boxs form-title bg-gray">内容選択</dt>
        <dd class="form-value values bg-gray">{{ request.contactContent }}</dd>

        <dt class="contact-form boxs form-title bg-white">内容記入欄</dt>
        <dd class="form-value values bg-white">{{ request.contactMsg }}</dd>
      </dl>
    </form>
  </div>

  <div class="btn-group">
    <v-btn
      @click.native="back"
      class="btn-cancel"
      depressed
    >
      キャンセル
    </v-btn>
    <v-btn
      @click.native="submit"
      type="submit"
      class="btn-confirm-submit"
      rounded
    >
      上記内容を送信
    </v-btn>
  </div>

  <v-footer
    dark
    padless
    style="margin-top: 260px;"
  >
    <p class="copyright-footer">Copyright © Paradigmshift Inc. All Rights Reserved.</p>
  </v-footer>
</div>
</template>

<script>
export default {
  computed: {
    request() {
      return this.$store.state.contact.request
    },
  },
  methods: {
    back() {
      const submit = window.confirm('入力内容を修正しますか？')
      if(submit) {
        this.$router.push('/contact')
      }
    },
    submit() {
      const submit = window.confirm('この内容で送信してもよろしいでしょうか？')
      if(submit) {
        this.$store.commit('contact/resetVuexState')
        this.$router.push('/contact/confirm/complete')
      }
    }
  }
}
</script>
